

import valles_mar from '../images/valles_mar.jpg';
import rancho_isabella from '../../static/rancho_isabella_entrada.jpg'
import ciudad_paraiso from '../images/ciudad_paraiso.jpg'
import las_puertas_mazatlan from '../../static/las_puertas_mazatlan.jpg'
import mar_paraiso from '../../static/mar_paraiso.jpg'
import puntazul_esmeralda from '../../static/puntazul_esmeralda.jpg'
import puntazul_residencial from '../../static/puntazul_residencial.jpg'
import valles_de_la_gloria from '../../static/valles_de_la_gloria.jpg'
// import ciudad_paraiso from '../images/ciudad_paraiso.jpg'

export const items = [
    // [
      {
        id: 1,
        title: "LAS PUERTAS D' MAZATLÁN",
        text: "Mazatlán, Sinaloa",
        url: "#",
        price: "Desde 300 USD al mes",
        btn: true,
        urlImg: las_puertas_mazatlan,
        description: "",
        lg: 3,
        // color: "#9f8a6f",
        // categoria: ["Modelo"]
      },
      {
        id: 2,
        title: "RANCHO ISABELLA",
        text: "Tecate, B.C.",
        url: "#", 
        btn: true,
        urlImg: rancho_isabella,
        description: "",
        lg: 3,
        color: "#000000",
        // categoria: ["ESR"]
      },
      {
        id: 3,
        title: "PUNTAZUL ZAFIRO",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: puntazul_residencial,
        price: "0.00",
        btn: true,
        description: "",  
        lg: 3,
        color: "#5B3C1D",
        // categoria: ["Modelo"]
      },
      {
        id: 4,
        title: "VALLES DEL MAR",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: valles_mar,
        price: "0.00",
        btn: true,
        description: "",
        lg: 3,
        color: "#886437",
        // categoria: ["Modelo"]
      }
    // ]
    ,
    // [
      {
        id: 5,
        title: "CIUDAD PARAÍSO LOS CABOS",
        text: "San José del Cabo, B.C.S.",
        url: "#",
        urlImg: ciudad_paraiso,
        price: "0.00",
        btn: true,
        description: "",
        lg: 3,
        color: "#7D6D5F",
        // categoria: ["Modelo"]
      },
      {
        id: 6,
        title: "PUNTAZUL DIAMANTE",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: puntazul_residencial,
        btn: true,
        description:
          "",
        lg: 3,
        color: "#68505B",
        // categoria: ["ESR"]
      },
      {
        id: 7,
        title: "VALLES DE LA GLORIA",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: valles_de_la_gloria,
        btn: true,
        description:
        "",
        lg: 3,
        color: "#2F343B",
        // categoria: ["Onix"]
      },
      {
        id: 8,
        title: "PUNTAZUL RESIDENCIAL",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: puntazul_residencial,
        btn: true,
        description:
        "",
        lg: 3,
        color: "#1F2D38",
        // categoria: ["Fraccionamiento"]
      }
    // ]
    ,
    // [
      {
        id: 9,
        title: "MAR PARAISO",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: mar_paraiso,
        btn: true,
        description:
          "",
  
        lg: 3,
        color: "#2C2C2E",
        // categoria: ["ESR"]
      },
      {
        id: 10,
        title: "PUNTAZUL ESMERALDA",
        text: "Rosarito, B.C.",
        url: "#",
        urlImg: puntazul_esmeralda,
        btn: true,
        description:
          "",
  
        lg: 3,
        color: "#2A2759",
        // categoria: ["ESR"]
      }
    // ],
   
  ]